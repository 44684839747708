import React from 'react'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Import CSS for the slider
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './ImageSlider.css'

const ImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 3000,
  }

  return (
    <Slider className='slick-slider' {...settings}>
      {images.map((image, index) => (
        <div key={index}>
          <GatsbyImage 
            image={getImage(image)} 
            alt={`Slide ${index + 1}`}
          />
        </div>
      ))}
    </Slider>
  )
}

export default ImageSlider