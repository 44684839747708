import React from 'react'
import { GoogleMap, LoadScript,Marker } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '400px'
}

const center = {
      lat: 54.6615093, // New York City latitude
    lng: -2.7691445 // New York City longitude
}

const GoogleMapComponent = () => {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyC-8K_Ay2XlYGzMJQm2ngHYjZqXXYQHfGk"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
      >
       <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  )
}

export default GoogleMapComponent

