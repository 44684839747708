import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import GoogleMapComponent from '../components/GoogleMap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageSlider from '../components/ImageSlider'

import BackgroundImage from 'gatsby-background-image'

const IndexPage = ({ }) => {
  
 const data = useStaticQuery(
  graphql`
    query {
      desktop: file(relativePath: { eq: "theofficepenrith.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920, grayscale: false) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allFile(filter: {relativeDirectory: {eq: "slider-images"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `
 )
  
  const images = data.allFile.edges.map(edge => edge.node)

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid;

  
  
return (
  
  <Layout>
    <SEO title="The Office" />
      <BackgroundImage
      Tag="section"
      className='  min-h-96 h-mobile md:h-desktop'
      fluid={imageData}
        backgroundColor={`#ffffff`}
      >
        <div className="w-screen flex h-full items-end">
          <div className="container mx-auto ">
            <div className="text-white flex gap-4 flex-col ml-auto md:w-1/2 p-3 py-6 md:p-6 md:py-8 backdrop-blur-xl bg-black/30 shadow-xl">
              <h1 className="text-3xl">The Office Penrith</h1>
              <p>Discover versatile office spaces in the heart of Penrith Business Park. From compact to spacious, our flexible leasing options cater to businesses of all sizes. Enjoy top-notch amenities including high-speed broadband, ample parking, and comprehensive services. Ideal for startups, growing companies, or specialized ventures, this prime location offers the perfect environment to nurture your business success.</p>
            <p className="font-bold text-lg ">Office sizes from 124 sq/ft to 644 sq/ft</p>
            <p className="font-bold text-lg ">For availability contact: <a className="text-white underline hover:no-underline" href="mailto:info@theofficepenrith.co.uk">info@theofficepenrith.co.uk</a></p>
            </div>
          </div>
        </div>
      
      </BackgroundImage>
    <div className="container mx-auto flex flex-col-reverse md:flex-row">
      <div className="flex flex-col-reverse md:flex-row md:w-1/2">
        <div className="p-3 py-6 md:p-6 ">
          <p className="font-bold text-xl mb-4">Current Resident Companies:</p>
          <ul>
           <li><a target="_blank" className="text-black no-underline hover:underline" href="https://bellcare.co.uk/">Bellcare Domiciliary Care Services</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="https://www.betterhearingclinic.co.uk">Better Hearing Clinic</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="https://www.bidservices.org">BID Services</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="https://www.cumbriafamilysupport.org.uk/">Cumbria Family Support</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="https://www.enterpriseanswers.co.uk/who-we-are/">Cumbria Community Asset/Reinv Trust</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="https://carlisle-eden-carers.org.uk/">Eden, Carer Support Carlisle</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="">JJ Ward</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="https://www.morrisonconstruction.co.uk/">Morrison Civil Engineering Ltd</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="https://myneurons.co.uk/">Mynuerons.co.uk</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="https://www.pansystems.com">Pan Systems Ltd</a></li>
            <li><a target="_blank" className="text-black no-underline hover:underline" href="">Seavor (South Cumbria) Ltd</a></li>
          </ul>
        </div>
        <div className="p-3 py-6 md:p-6 ">
          <p className="font-bold text-xl mb-4">Features:</p>
          <ul>
              <li>Easy access to M6/J40</li>
              <li>Modern Building</li>
              <li>Car Parking</li>
              <li>Broadband</li>
              <li>Self contained office</li>
              <li>Communal kitchen</li>
              <li>Excellent Broadband</li>
              <li>Small Business environment</li>
              <li>Energy efficient</li>
              <li>Environmentally conscious</li>
              <li>Disabled Facilities</li>
          </ul>
        </div>
        </div>
        <div className="md:w-1/2">
          <ImageSlider images={images} />
        </div>
      </div>
    <div className="">
      <GoogleMapComponent/>
      </div>
     

  </Layout>
)
}



export default IndexPage
